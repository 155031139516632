@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


.roboto-slab-100 {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu'), url('./fonts/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Exo-Reg';
    src: local('Exo-Reg'), url('./fonts/Exo2-VariableFont_wght.ttf') format('truetype');
}

* {
    box-sizing: border-box;
}

.logo-name img {
    height: 75px;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    margin-top: 1px;
    padding-left: 5vw;
}

.navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding-top: 15px;
    margin-right: 2vw;
}

.navigation a {
    text-decoration: none;
    display: block;
    padding-right: 1.1vw;
    padding-left: 1.1vw;
    padding-top: 15px;
    font-weight: bold;
    color: white;
    transition: all 0.2s ease-in-out 0.3s;
    position: relative;
    z-index: 0;
}

.sticked {
    position: fixed;
    width: 100%;
    box-shadow: 0 2px 2px -2px gray;
    border-bottom-right-radius: 2em;
    border-bottom-left-radius:  2em;
    top:0;
    background: rgba(116, 216, 255, 0.3);
    transition: all 0.15s ease-in 0.15s;
    z-index: 100;
}

.body {
    display: flex;
    z-index: 5;
    width: 100%;
    height: 936px;
    justify-content: center;
    font-family: "Exo-Reg", sans-serif;
}

.boost-text {
    position: absolute;
    color: white;
    border-radius: 50px;
    /* Makes the outer pill rounded */
    height: 40px;
    /* Add padding for inner space */
    display: flex;
    align-items: center;
    justify-content: center;
}

.boost-text span:nth-child(1) {
    color: white;
    /* Padding inside the inner pill */
    border-radius: 50px;
    /* Makes the inner pill rounded */
    background-color: rgb(7, 101, 173);
    /* Background color for the inner pill */
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.elements{
    position: absolute;
    margin-top: 128px;
    border: #374151 1px solid;
    z-index: 200;
    width: 130px;
    right: 5px;
    top: 3%;
    border-radius: 2vh;
}

.under {
    position: relative;
    color: linear-gradient(90deg, rgb(74, 158, 214) 0%, rgba(126, 126, 240, 0.6) 100%);
    transition: all .35s ease;

    &::before {
        content: ""; 
        position: absolute;
        width: 70%;
        left: 15%;
        height: 4px;
        border-radius: 20px;
        bottom: 0;
        background: linear-gradient(90deg, rgb(74, 158, 214) 0%, rgba(126, 126, 240, 0.6) 100%);
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.1s ease-in-out 0s; 
        transition: all 0.1s ease-in-out 0s; 
    }   

    &:hover {
        color: linear-gradient(90deg, rgb(74, 158, 214) 0%, rgba(126, 126, 240, 0.6) 100%);

        &::before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }   
    }   
}

.bar {
    display: block;
    width: 20px;
    height: 3px;
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
    border-radius: 10px;
}

.hambr .bar:nth-child(2) {
    opacity: 0;
}

.hambr .bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
    margin-bottom: -1px;
}

.hambr .bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
    margin-right: 10px;
}

.spin:hover
{
    animation: spin 0.25s 1 ease-in-out;
}

@keyframes spin {
    from{
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}
.hRaise{
    transition: transform 0.1s ease;
}
.hRaise:hover
{
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(62, 59, 59, 0.7);
}

.spin{
    transition: transform 0.1s ease;
}
.scale {
    transform: scale(1.15);
}

.z-yes{
    position: relative;
    justify-content: space-between;
    z-index: 10001;
}

@media screen and (min-width: 436px) {
    .chip{
        transform: scale(1.15);
    }
}