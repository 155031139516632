.all {
  background-color: #001220;
}

.TOP {
  text-align: center;
  margin: 0;
  padding: 0;
  background: #001220;
  height: 1000px;
  width: 100%;
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it is on top */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for better visibility */
}

.wave {
  display: flex;
  z-index: 70;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: visible;
  line-height: 0;
  transform: rotate(180deg);
  margin-bottom: -3px;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(107% + 1.3px);
  height: 152px;
}

.wave .shape-fill {
  fill: #3c31dd;
  margin-bottom: -5px;
}

.spacer {
  display: flex;
  z-index: 77;
  width: 100%;
  margin-top: -5px;
  height: 220px;
}


.layer1 {
  background-image: url('./imgs/pattern.svg');
}

.waveDown{
  background-image: url('./imgs/wavedown.svg');
  display: flex;
  z-index: 70;
  width: 100%;
  margin-top: -5px;
}

.FOOTER
{
  background: #001220;
  z-index: 69;
}