.color12 {
    background-color: #ff6f61;
    z-index: 77 ;
}

.carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  /*.App {
  font-family: sans-serif;
  text-align: center;
}*/

.card-main {
    width: 18rem;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 17px;
    border-radius: 10px;
  }
  
  .circle-div {
    background-color: grey;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  
  .card-title {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 19px;
  }
  
  .card-text {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    text-align: center;
  }
  
  #sponsors
  {
    scroll-margin-top: 140px;
  }